import React from "react";
import s from './Media.module.scss';
import { useStaticQuery, graphql } from "gatsby";
import CenterMode from'./CenterMode';
import GraphImg from "graphcms-image";



const Medios = () => {
    const homeMedioses = useStaticQuery(graphql`{
        gcms {
            homeMedioses (where: {status: PUBLISHED}){
                id
                title
                link
                imgMobileColor{
                    handle
                    width
                    height
                }
            }
        }
    }`).gcms.homeMedioses;

    return (
        <div className={s.container}>
            <p className={s.title}>{homeMedioses[0].title}</p>
            <div className={s.mediosMobile}>
                <CenterMode medios={true} slidesNumber={4}>
                    {homeMedioses.map((src, index) => {
                        return (
                            <div key={index} className={s.imgMobile}>
                                <a target='__blank' href={src.link} alt="link a la nota" >
                                    <GraphImg key={index} image={src.imgMobileColor} maxWidth={src.imgMobileColor.width} maxHeight={src.imgMobileColor.height} withWebp={true} alt="iconos de medios" className={s.graphImge}/>
                                </a>
                            </div>
                        )
                    })}
                </CenterMode>
            </div>
        </div>
    )
}

export default Medios
