import React from "react";
import s from './OurProcess.module.scss';
import icon1 from '../images/icons/Icono-Lo-solicitas-online.svg';
import icon2 from '../images/icons/Icono-te-asesoras.svg';
import icon3 from '../images/icons/Icono-recibis-tu-dinero.svg';
import ProcessCard from './ProcessCard';

const OurProcess = () => {
  return(
      <div className={s.container}>
            <h1 className={s.title}>Nuestro proceso: <span>Rápido y Seguro</span></h1>
            <div className={s.cards}>
                <ProcessCard src={icon1} title={'Lo solicitás online'} description={'La plataforma te ofrece una cotización personalizada y te pre-aprueba de forma automática.' } number={'1'}/>
                <ProcessCard src={icon2} title={'Te asesorás con un experto'} description={'Nuestro equipo te contacta en el acto y te brinda atención personalizada de punta a punta.'} number={'2'}/>
                <ProcessCard src={icon3} title={'¡Recibís tu dinero!'} description={'¡Firmás los documentos en nuestras oficinas y te transferimos el dinero en el acto!'} number={'3'}/>
            </div>
      </div>
  )
}

export default OurProcess
