import React from "react";
import axios from 'axios';
import { URL_BASE } from '../config.js';
import CarPurchaseForm from './CarPurchaseForm';
import ConfirmMessage from './ConfirmMessage';
import s from './CarPurchaseBody.module.scss';
import iconsCp from '../images/icons/iconos-car-p.svg';
import { isValidCharacter } from '../../utils';




class CarPurchaseBody extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        agency: '',
        phone: '',
        email: '',
        sendInfo: false,
        errors: {
            email: '',
            phone: '', 
        },
    }

    handleChange = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        if(isValidCharacter(name, value)){
            this.setState({
                [name]: value,
            });
        }
    }

    submitUserInfo = () =>{
        const {first_name, last_name, phone, email, agency } = this.state;

        axios.defaults.withCredentials = true
        axios.defaults.baseURL = URL_BASE;
         
        axios.post(`/api/v3/contact/`,
        {   code: "broker",
            first_name: first_name, 
            last_name: last_name,
            phone: phone,
            email: email,
            agency: agency,
        },)
        .then((response)=> {
            this.setState({
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                agency: '',
                sendInfo: true,
            });
        })
        .catch(({ response }) => {
            const errors = {};
            Object.keys(response.data).forEach(field => {
                errors[field] = response.data[field];
            });
            this.setState({
                errors: errors,
            });
        });   
    }

    render(){
        const {first_name, last_name, agency, phone, email, errors, sendInfo} = this.state;
        return(
            <div className={s.carPurchaseBody}>
               <div className={s.blueBackground}>
                   <div className={s.textWrapper}>
                       <h1 className={s.title}>¿Sos una agencia o concesionaria?</h1>
                        <p className={s.text1}>¡Formá parte de nuestra red y vendé más!</p>
                        <p className={s.text}> Ofrecemos financiamiento de calidad con cuotas accesibles, aprobación rápida y asesoramiento personalizado.</p>
                        <img className={s.iconsCp} src={iconsCp} alt='iconos de auto y préstamo'/>
                   </div>
               </div>
               <div className={s.formCard}>
                   {(sendInfo)
                        ? <ConfirmMessage message={'Un asesor comercial se estará contactando con vos en las próximas horas.'}/>
                        : <CarPurchaseForm first_name={first_name} last_name={last_name} agency={agency} phone={phone} email={email} errors={errors} handleChange={this.handleChange} submitUserInfo={this.submitUserInfo} />
                    }
               </div>
            </div>
        )
    }
}

export default CarPurchaseBody
