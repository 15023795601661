import React from "react";
import s from './Blog.module.scss';
import Arrow from '../images/icons/blue-arrow-right.svg';
import CenterMode from'./CenterMode';
import './CenterMode1.css';
import './CenterMode.css';
import { useStaticQuery, graphql } from "gatsby";
import GraphImg from "graphcms-image";


const Blog = () => {
    const blogs = useStaticQuery(graphql`{
        gcms {
            blogs (where: {status: PUBLISHED}){
                id
                bigImg{
                    handle
                    width
                    height
                }
                date
                mainText {
                    text
                }
                link
            }
        }
    }`).gcms.blogs;

    return(
        <div className={s.actionContainer}>
            <p className={s.title}>Revista Agilis</p>
            <CenterMode>
              {blogs.map((item, index) =>
                <div className="cardBlog" key={index}>
                    <GraphImg key={index} className="bigImgBlog" image={item.bigImg} maxWidth={400} withWebp={true} alt="Imagen principal"/>
                    <div className={s.infoBox}>
                        <p className={s.date}>{item.date}</p>
                        <p className={s.mainText}>{item.mainText.text}</p>
                    </div>
                    <a key={`a_${index}`} className={s.footer} href={item.link} target='__blank' alt={"ir a la publicación"}>
                        <p className={s.footerLine}>Ver publicación</p>
                        <img src={Arrow} alt="Flecha azul"/>
                    </a>
                </div>
              )}
            </CenterMode>
        </div>
    )
}

export default Blog
