import React from 'react';
import s from './SingleCreditButton.module.scss';

const SingleCreditButton = ({ creditText, href }) => {

    return (
        <a href={href} className={s.link}>
            <button className={s.buttonContainer}>
                <p className={s.singleCreditText}>{creditText}</p>
            </button>
        </a>
    )
};

export default SingleCreditButton;
