import React from "react";
import s from './ProcessCard.module.scss';

const ProcessCard = ({ src, title, description, number }) => {
  return(
      <div className={s.cardContainer}>
          <div className={s.number}>{number}</div>
          <div className={s.mobileIcons}>
                <img className={s.icon} src={src} alt={"iconos"}/>
                <h6 className={s.title}>{title}</h6>
          </div>
          <p className={s.description}>{description}</p>
      </div>
  )
}

export default ProcessCard
