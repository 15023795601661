import React from 'react';
import s from './ConfirmMessage.module.scss';
import blueCheck from '../images/icons/1366-tilde.svg';



const ConfirmMessage = ({message}) => {

    return (
        <div className={s.infoWraper}>
            <img className={s.blueCheck} src={blueCheck} alt='Check Azul'/>
            <p className={s.text}>{message}</p>
        </div>
    )
};

export default ConfirmMessage;
