import React from "react";
import s from './OurNumbers.module.scss';
import CountUp from 'react-countup';


const Numbers = () => {
    return (
        <div className={s.container}>
            <p className={s.title}>AGILIS en números</p>
            <div className={s.boxContainer}>
                <div>
                    <p className={s.numbers}>
                        <CountUp
                          start={10000}
                          end={65000000}
                          duration={8}
                          delay={3}
                          separator=" "
                          prefix="$ "
                        />
                    </p>
                    <p className={s.line}>Monto en créditos otorgados</p>
                </div>
                <div>
                    <p className={s.numbers}>
                        <CountUp
                          start={50}
                          end={3500}
                          duration={8}
                          delay={3}
                          separator=" "
                          prefix="+ "
                        />
                    </p>
                    <p className={s.line}>Clientes Pre-Aprobados en 2019</p>
                </div>
                <div>
                    <p className={s.numbers}>
                        <CountUp
                          start={0}
                          end={150}
                          duration={10}
                          delay={3}
                          separator=" "
                          prefix="+ "
                        />
                    </p>
                    <p className={s.line}>Créditos Pre-Aprobados la semana anterior</p>
                </div>
            </div>
        </div>
    )
}

export default Numbers
